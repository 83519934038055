<template>
    <div class="express">
        <full-page :options="options" id="fullpage2">
            <div class="section bg">
                <div class="section-all">
                    <div class="express__kj animate__animated animate__fadeInUp">
                        
                    </div>
                    <div class="express__font animate__animated animate__fadeInUp">
                        
                        <span class="size64">企业用车</span>
                        <span class="size36">提供企业用车和管理解决方案，<br>
可通过后台实现行程和费用管控，简化流程，提升效率。</span>
                    </div>
                </div>
            </div>
            <div class="section">
                <div class="section-all">
                    <div class="express-title">
                        <span class="size36">用车体验</span>
                        <span class="size16">Experience</span>
                    </div>
                    <div class="express-list">
                        <div class="express-list__li">
                            <div class="express-list__li-center">
                                <img class="one" :class="listTop" src="../../../static/images/qy/ck-one.png" />
                                <div class="one-img one-bg">
                                    <span>01</span>
                                </div>
                                <span class="size36" :class="listBottom">专业服务</span>
                            </div>
                        </div>
                        <div class="express-list__li">
                            <div class="express-list__li-center">
                                <img class="two" :class="listTop" src="../../../static/images/qy/ck-two.png" />
                                <div class="one-img two-bg">
                                    <span>02</span>
                                </div>
                                <span class="size36" :class="listBottom">节约成本</span>
                            </div>
                        </div>
                        <div class="express-list__li">
                            <div class="express-list__li-center">
                                <img class="three" :class="listTop" src="../../../static/images/qy/ck-three.png" />
                                <div class="one-img three-bg">
                                    <span>03</span>
                                </div>
                                <span class="size36" :class="listBottom">管理透明</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section bottom-bg">
                <div class="section-all">
                    <div class="all-title">
                        <span class="size36">用车场景</span>
                        <span class="size16">Scenes</span>
                    </div>
                    <div class="business-swiper">
                        <swiper class="swiper" :options="swiperOption"
                            ref="Myswiper"
                            @slideChange="changeswiper">
                            <swiper-slide v-for="(item,index) in List"
                            :key="index+'v'">
                                <div class="business-swiper__list">
                                    <div class="business-swiper__view">
                                        <div class="business-swiper__view-num">0{{index+1}}</div>
                                        <span class="size28 font" :class="boClass">{{item.title}}</span>
                                        <div class="business-swiper__view-icon" :class="boClass">
                                            <img :src="item.img" />
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
            </div>
        </full-page>
    </div>
</template>
<script>
import '../../../static/css/fullpage.min.css';
// 引入插件
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
export default {
    // components: {
    //     Swiper,
    //     SwiperSlide
    // },
    data(){
        return{
            options:{
                afterRender:this.afterRender,
                navigation:true,
                onLeave:this.onLeave
            },
            expreIndex:0,
            swiperOption:{
                slidesPerView:4.3,
                spaceBetween: 0,
                nextButton:'.swiper-container-next',
                prevButton:'.swiper-container-prev',
                pagination: '.swiper-pagination',
                paginationClickable: true,
                grabCursor: true
            },
            List:[
                {
                    title:'会议用车',
                    img:require('../../../static/images/qy/one.png')
                },
                {
                    title:'商务接待',
                    img:require('../../../static/images/qy/two.png')
                },
                {
                    title:'差旅用车',
                    img:require('../../../static/images/qy/three.png')
                },
                {
                    title:'接送机',
                    img:require('../../../static/images/qy/four.png')
                },
                {
                    title:'员工加班',
                    img:require('../../../static/images/qy/five.png')
                }
            ],
            ckIndex:0,
            swiperShow:true,
            boClass:'',
            listTop:'',
            listBottom:''
        }
    },
    computed:{
        swiper(){
            return this.$refs.Myswiper.swiper;
        }
    },
    mounted(){
        this.routerTo(6);
    },
    methods:{
        expreTap(index){
            this.expreIndex = index;
            this.ckIndex = 0;
        },
        afterRender(){

        },
        onLeave(e,n){
            const {index} = n;
            switch(index){
                case 1:
                  this.listTop = 'animate__animated animate__fadeInDown';
                  this.listBottom = 'animate__animated animate__fadeInUp';
                  break;
                case 2:
                  this.boClass = '';
                  break;
            }
        },
        changeswiper(){
            this.ckIndex = this.$refs.Myswiper.$swiper.activeIndex;
        }
    }
}
</script>
<style>
.swiper-pagination .swiper-pagination-bullet{
    width:10px !important;
    height:10px !important;
    display: inline-block;
    border-radius: 100%;
    background:rgba(0,0,0,0) !important;
    border:1px solid #FFFFFF !important;
    margin:0 5px !important;
}
.swiper-pagination .swiper-pagination-bullet-active{
    width:10px !important;
    height:10px !important;
    display: inline-block;
    border-radius: 100%;
    background:#FFFFFF !important;
    border:1px solid #FFFFFF !important;
}
</style>
<style lang="less" scoped>
@import url('../../../static/css/business.less');
@import url('../../../static/css/media.less');
</style>